<template>
  <base-modal
    modal-title="Confirm Message for Product Line Report"
    @hidden="emitRejectedEvent()"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div class="text-justify text-sm mb-2 leading-5">
          Your report criteria returned <strong>{{ recordCount }} record{{ recordCount && recordCount > 0 ? 's' : '' }}</strong>. Click <strong>'Yes’</strong> to continue to generate the report. Click <strong>'No'</strong> to refine your criteria. Depending on the number of results the report may take a few minutes to generate. Please do not close your browser or log off your session while the report is being generated.
        </div>
        <div class="text-right">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            @click="emitAcceptedEvent()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="emitRejectedEvent()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'ConfirmProductLineReportModal',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    props: {
        recordCount: {
            type: Number,
            default: 0,
            description: 'Number of records'
        }
    },

    emits: [
        'accepted',
        'rejected'
    ],

    setup (props, { emit }) {
        const emitAcceptedEvent = () => {
            emit('accepted');
        };
        const emitRejectedEvent = () => {
            emit('rejected');
        };

        return {
            emitAcceptedEvent,
            emitRejectedEvent
        };
    }
};
</script>
